import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis, opsverseAuthApiRef } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
  SignInProviderConfig,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { ServicePage } from '@internal/plugin-service';
import LightIcon from '@material-ui/icons/WbSunny';
import { LightTheme } from './theme';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import Cookies from 'js-cookie';
import posthog from 'posthog-js';
import {
  RELATION_OWNER_OF,
  RELATION_OWNED_BY,
  RELATION_CONSUMES_API,
  RELATION_API_CONSUMED_BY,
  RELATION_PROVIDES_API,
  RELATION_API_PROVIDED_BY,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_DEPENDS_ON,
  RELATION_DEPENDENCY_OF,
} from '@backstage/catalog-model';

window.addEventListener('message', event => {
  if (event.data && event.data.type === 'authorization_response') {
    const accessToken = event.data.response.providerInfo.accessToken;
    Cookies.set('opsverse-token', accessToken, {
      domain: `${window.location.hostname}`,
      expires: 7,
      sameSite: 'lax',
    });
    Cookies.set('oauth2-refresh-token', accessToken, {
      domain: `${window.location.hostname}`,
      expires: 7,
      sameSite: 'lax',
      path: '/api/auth/oauth2',
    });
  }
});
import { FossaPage } from '@backstage/plugin-fossa';
import { EntitySnykContent } from 'backstage-plugin-snyk';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';

const oauthProvider: SignInProviderConfig = {
  id: 'oauth-auth-provider',
  title: 'Sign In',
  message: 'Sign in using Opsverse',
  apiRef: opsverseAuthApiRef,
};

const app = createApp({
  apis,
  components: {
    SignInPage: props => (
      <SignInPage {...props} auto={false} provider={oauthProvider} />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'my-theme',
      title: 'Light Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <ThemeProvider theme={LightTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <Mermaid config={{ theme: 'default', themeVariables: { lineColor: '#000000' } }} />
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            selectedKinds: ['component', 'domain', 'system', 'api', 'group'],
            selectedRelations: [
              RELATION_OWNER_OF,
              RELATION_OWNED_BY,
              RELATION_CONSUMES_API,
              RELATION_API_CONSUMED_BY,
              RELATION_PROVIDES_API,
              RELATION_API_PROVIDED_BY,
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
          }}
        />
      }
    />
    <Route path="/service" element={<ServicePage />} />
    <Route path="/fossa" element={<FossaPage />} />
    <Route path="/snyk" element={<EntitySnykContent />} />
  </FlatRoutes>
);

const App = () => {
  useEffect(() => {
    window.sessionStorage.clear();
    if (window.location.href.indexOf('opsverse-demo') > -1) { 
      window.sessionStorage.setItem('isDemo', 'true');
    }

  }, []);

  useEffect(() => {
    posthog.init('phc_akuxXu3uKa806fz39Dm9w936pkK5teNyVBXaSbfzBAh', {
      api_host: 'https://opsverse-venus-posthog.us-east4.gcp.opsverse.cloud',
    });
  }, []);
  return (
    <AppProvider>
      <AlertDisplay />
      <OAuthRequestDialog />
      <AppRouter>
        <Root>{routes}</Root>
      </AppRouter>
    </AppProvider>
  );
};

export default App;
