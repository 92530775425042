import {
  HomePageToolkit,
  HomePageStarredEntities,
} from '@backstage/plugin-home';

import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { Avatar, Button, Grid, Tooltip, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import CategoryIcon from '@material-ui/icons/Category';
import SideDrawer from '@internal/plugin-service/src/components/atoms/sideDrawer';
import ChatAi from '../Aiden/MainContent';
import aiden from '../Aiden/MainContent/Deck/aiden.png';

export default {
  title: 'Plugins/Home/Templates',
  decorators: [],
};

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    margin: 'auto',
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const [showAiBot, setShowAiBot] = useState(false);

  return (
    <Page themeId="home">
      <Content>
        <Grid container justifyContent="center" spacing={6}>
          <Grid container item xs={12} alignItems="center" direction="row">
            <HomePageSearchBar
              classes={{ root: classes.searchBar }}
              placeholder="Search"
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <HomePageStarredEntities />
            </Grid>
            <Grid item xs={12} md={6}>
              <HomePageToolkit
                tools={Array(1).fill({
                  url: 'catalog',
                  label: 'Go to catalog',
                  icon: <CategoryIcon />,
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{
            position: 'relative',
            height: '100vh',
            width: '100vw',
            padding: '50px',
          }}
        >
          <Tooltip title="Aiden">
            <Button
              variant="contained"
              onClick={() => setShowAiBot(true)}
              style={{
                position: 'fixed',
                bottom: 50,
                right: 50,
                backgroundColor: 'white',
                height: '5rem',
                width: '5rem',
                borderRadius: '50%',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,
              }}
            >
              <Avatar
                src={aiden}
                alt="Aiden Avatar"
                style={{
                  width: '60%',
                  height: '60%',
                  borderRadius: '50%',
                  marginBottom: '5%',
                }}
              />
            </Button>
          </Tooltip>
        </div>
        <SideDrawer
          width="50vw"
          open={showAiBot}
          close={() => setShowAiBot(false)}
        >
          <ChatAi/>
        </SideDrawer>
      </Content>
    </Page>
  );
};
